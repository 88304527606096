<template>
  <Form :data="{step: 'success', title: 'Welcome to our estimating system!'}">
    <template #success>
      <div class="no-auth">
        <p class="no-auth__desc">This is not DIY. Please proceed to get an estimate for measured and installed insect
          and security screens and doors.</p>
        <Button :data="btnOptions" class="no-auth__btn"/>
        <p class="no-auth__desc">Note: The estimate price may vary after site inspection.</p>
        <div class="no-auth__log no-auth__desc">Already have an Account?
          <Button :data="btnLogin" class="link_dotted"/>
          <!--<router-link to="/login" class="link_dotted">LOG IN</router-link>--></div>
      </div>
    </template>
  </Form>
</template>

<script>
import Form from '@/components/auth/Form.vue'
import Button from '@/components/form/Button.vue'
import {reactive} from '@vue/reactivity'
import {useRoute} from "vue-router";


export default ({
  components: {
    Form,
    Button,
  },
  setup() {
    const route = useRoute()
    const btnOptions = reactive({
      title: 'PROCEED TO ESTIMATE',
      type: 'primary',
      href: '/create',
      icon: 'fas fa-check',
    })

    const btnLogin = reactive({
      title: 'LOG IN',
      type: 'primary',
      href: '/login',
      icon: 'fas fa-sign-out ',
    })

    return {
      btnOptions,
      btnLogin
    }
  },
})
</script>

<style lang="scss" scoped>
::v-deep .auth-form__success {
  width: 415px;
  margin: 0;
}

.no-auth {
  &__desc {
    margin-bottom: 14px;
    text-align: left;
    font-size: 14px;
    line-height: 140%;
  }

  &__btn {
    margin-bottom: 7px;
    padding: 10px !important;
  }

  &__log {
    text-align: left;
    margin-bottom: 0;

    a {
      margin-left: 50px;
    }
  }
}

.no-auth__log.no-auth__desc {
  display: inline-grid !important;
  font-size: 18px !important;
  width: 100%;
  font-weight: bold !important;
}

.link_dotted {
  max-width: fit-content;
  margin: 12px 0 0 !important;
  background: var(--PrimaryButtonColour);
  color: var(--PrimaryButtonTextColour);
  padding: 10px;
}

@media (max-width: 768px) {
  ::v-deep .auth-form__success {
    width: 100%;
  }
}
</style>
